<template>
    <div
        :class="['percircle', { animate, perclock, perdown, initialized: initialized }]"
        @mouseenter="startHover"
        @mouseleave="stopHover"
        @click="timerReset"
        :style="`
            --var-progress-bar-size: ${size}px;
            --var-progress-font-size: ${(80 * size / 100)}px;
            --var-progress-stroke-width:${strokeWidth};
            --var-progress-stroke-hover-width:${70 * strokeWidth/100};
            --var-progress-font-size: ${(80 * size / 100)}px;
            --var-progress-empty-color: ${emptyColor};
        `"
    >
        <div :style="{ color: textColor }">
            <label class="label">{{ displayText }}<span v-if="!text && (renderedPercent || displayTextAtZero)">%</span></label>
            <h5 v-if="labelText">{{ labelText }}</h5>
        </div>
        <svg viewBox="0 0 120 120">
            <circle class="filler-bar circle-bar" cx="60" cy="60" r="50" :fill="background" />
            <circle
                class="progress-bar circle-bar"
                cx="60"
                cy="60"
                r="50"
                fill="none"
                stroke-linecap="round"
                :stroke="color"
                :pathLength="fillerMax"
                :stroke-dasharray="fillerMax"
                :stroke-dashoffset="fillerOffset"
            />
        </svg>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                hovering: false,
                initialized: false,
                clockInterval: null,
                date: new Date(),
                secsRemaining: this.secs,
                timerInterval: null,
            };
        },

        props: {
            //Whether to animate the progress bar on load (or view)
            animate: {
                type: Boolean,
                default: true,
            },
            //Display a clock in the percircle
            perclock: {
                type: Boolean,
                default: false,
            },
            //Display a countdown in the percircle
            perdown: {
                type: Boolean,
                default: false,
            },
            //The amount of seconds to countdown.
            secs: {
                type: Number,
                default: 15,
            },
            //Text to display when countdown has completed.
            timeUpText: {
                type: String,
                default: 'Done!',
            },
            //Whether to reset the countdown on percircle click
            resetOnClick: {
                type: Boolean,
                default: true,
            },
            //Whether to display text even when the percentage is 0
            displayTextAtZero: {
                type: Boolean,
                default: true,
            },
            //The wrapper class, generally used for setting colors.
            //Available options:
            //red, blue, green, purple, yellow, pink
            //Can also be an inline color
            color: {
                type: String,
                default: '#A6CE39',
            },
            //How full the bar is
            percent: {
                type: Number,
                default: 0,
            },
            //Text to display in circle
            text: {
                type: String,
                default: '',
            },
            //Label text to display in circle
            labelText: {
                type: String,
                default: '',
            },
            animateOnScroll: {
                type: Boolean,
                default: false,
            },
            size: {
                type: Number,
                default: 180,
            },
            strokeWidth: {
                type: Number,
                default: 8,
            },
            background: {
                type: String,
                default: 'transparent',
            },
            emptyColor: {
                type: String,
                default: '#f4f4f4',
            },
        },

        watch: {
            perclock: {
                immediate: true,
                handler (val) {
                    const vm = this;

                    if (val) {
                        vm.setClockInterval();
                    } else {
                        vm.deleteClockInterval();
                    }
                },
            },

            perdown: {
                immediate: true,
                handler (val) {
                    const vm = this;

                    if (val) {
                        vm.setTimerInterval();
                    } else {
                        vm.deleteTimerInterval();
                    }
                },
            },
        },

        computed: {
            fillerMax () {
                const vm = this;

                return vm.perclock ? 60 : vm.perdown ? vm.secs : 100;
            },

            fillerOffset () {
                const vm = this;
                let max = vm.fillerMax;
                let val = vm.renderedPercent;

                if (vm.perclock) {
                    val = vm.date.getSeconds();
                }

                if (vm.perdown) {
                    val = vm.secsRemaining;
                }

                return max - val;
            },

            renderedPercent () {
                const vm = this;

                if (!vm.initialized) {
                    return 0;
                }

                return vm.percent > 100 ? 100 : vm.percent < 0 ? 0 : vm.percent;
            },

            displayText () {
                const vm = this;
                let text = vm.text;

                if (vm.perclock) {
                    text = vm.getPadded(vm.date.getHours()) + ':' + vm.getPadded(vm.date.getMinutes()) + ':' + vm.getPadded(vm.date.getSeconds());
                } else if (vm.perdown) {
                    if (vm.secsRemaining <= 0) {
                        text = vm.timeUpText;
                    } else {
                        text = vm.secsRemaining;
                    }
                } else if (vm.renderedPercent || vm.displayTextAtZero) {
                    text = text || `${vm.percent}`;
                }

                return text;
            },

            textColor () {
                const vm = this;

                return vm.hovering ? vm.color : '';
            },
        },

        beforeUnmount () {
            const vm = this;

            vm.deleteClockInterval();
            vm.deleteTimerInterval();
            vm.deleteEventListeners();
        },

        mounted () {
            const vm = this;

            if ((vm.animateOnScroll && vm.isInViewport()) || !vm.animateOnScroll) {
                vm.initPercircle();
            } else {
                vm.setScrollEventListener();
            }
        },

        methods: {
            isInViewport () {
                const vm = this;
                const rect = vm.$el.getBoundingClientRect();

                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <=
                    (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <=
                    (window.innerWidth || document.documentElement.clientWidth)
                );
            },

            initPercircle () {
                const vm = this;

                setTimeout(() => {
                    //Display start up animation
                    vm.initialized = true;
                }, 0);
            },

            setClockInterval () {
                const vm = this;

                vm.clockInterval = setInterval(() => {
                    vm.date = new Date();
                }, 1000);
            },

            deleteClockInterval () {
                const vm = this;

                if (vm.clockInterval) {
                    clearInterval(vm.clockInterval);
                }

                vm.clockInterval = null;
            },

            setTimerInterval () {
                const vm = this;

                vm.timerInterval = setInterval(() => {
                    vm.secsRemaining -= 1;

                    if (vm.secsRemaining <= 0) {
                        vm.deleteTimerInterval();
                    }
                }, 1000);
            },

            deleteTimerInterval () {
                const vm = this;

                if (vm.timerInterval) {
                    clearInterval(vm.timerInterval);
                }

                vm.timerInterval = null;
            },

            timerReset () {
                const vm = this;

                if (!vm.perdown || !vm.resetOnClick) {
                    return;
                }

                vm.deleteTimerInterval();
                vm.secsRemaining = vm.secs;
                vm.setTimerInterval();
            },
            // display a presentable format of current time
            getPadded (val) {
                return val < 10 ? '0' + val : val;
            },

            startHover () {
                const vm = this;

                vm.hovering = true;
            },

            stopHover () {
                const vm = this;

                vm.hovering = false;
            },

            setScrollEventListener () {
                const vm = this;

                vm.scrollEventListener = window.addEventListener(
                    'scroll',
                    vm.scrollEventListener
                );
            },

            scrollEventListener () {
                const vm = this;

                if (!vm.initialized && vm.animateOnScroll && vm.isInViewport()) {
                    vm.initPercircle();
                }
            },

            deleteEventListeners () {
                const vm = this;

                window.removeEventListener('scroll', vm.scrollEventListener);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .percircle {
        position: relative;
        font-size: var(--var-progress-font-size, 120px);
        width: var(--var-progress-bar-size, 180px);
        height: var(--var-progress-bar-size, 180px);
        background: transparent;
        border-radius: 50%;

        svg {
            transform: rotate(-90deg);
        }

        &.animate {

            &>span,
            .circle-bar {
                transition: all 0.2s ease-in-out;
            }
        }

        &>div {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            position: absolute;
            z-index: 1;
            width: 100%;
            white-space: nowrap;
            font-size: 0.2em;
            font-weight: 500;
            color: #121525;

            &>label {
                
                &>span {
                    font-size: 70%;
                }
            }

            &>h5 {
                font-size: 65%;
                color: #5a5a5a;
                font-weight: 400;
                margin: 0;
            }
        }

        &:hover {
            cursor: default;

            &>span {
                -webkit-transform: scale(1.3);
                -moz-transform: scale(1.3);
                -ms-transform: scale(1.3);
                -o-transform: scale(1.3);
                transform: scale(1.3);
                color: #307bbb;
            }

            .circle-bar {
                r: 52;
                stroke-width: var(--var-progress-stroke-hover-width, 5);
            }
        }

        .filler-bar {
            stroke: var(--var-progress-empty-color, rgba(0, 0, 0, 0.1));
        }

        .circle-bar {
            stroke-width: var(--var-progress-stroke-width, 8);
        }
    }

    .perclock>span {
        font-size: 0.15em;
    }

    .perclock.animate .circle-bar,
    .perdown.animate .circle-bar {
        transition: stroke-width 0.2s ease-in-out, r 0.2s ease-in-out;
    }
</style>